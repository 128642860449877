import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Locales } from "./shared/Locales";
import { routes } from "./routes/Routes";

const router: any = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: routes.map((route: any) => ({
      path: route.path,
      element: route.element,
    })),
  },
]);

if (!router.state.matches[1]?.pathname) {
  router.navigate("/home");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Locales>
    <RouterProvider router={router} />
  </Locales>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
